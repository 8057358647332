<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="table--general tableClustering">
          <v-data-table :headers="headers" :items="dataTable.itemsTable" :hide-default-header="false"
            :hide-default-footer="true" :light="true">
            <template v-slot:[`item.sentiment`]="{ item }">
              <v-icon v-if="item.sentiment == 'neutral' || item.sentiment == 'neu'" small color="#828a96">
                far fa-hand-point-right
              </v-icon>
              <v-icon v-if="item.sentiment == 'pos'" small color="#9fd895">
                far fa-thumbs-up
              </v-icon>
              <v-icon v-if="item.sentiment == 'neg'" small color="#ff7e7e">
                far fa-thumbs-down
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClusteringTable",
  props: ["dataTable"],
  data() {
    return {
      headers: [
        {
          text: `${this.dataTable.text}`,
          align: "left",
          value: "value",
          class: ["font-weight-bold", "titleColor", "font15pt"],
          sortable: false
        },
        {
          text: "Sentiment",
          align: "center",
          value: "sentiment",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          width: "100",
          sortable: false
        },
        {
          text: "Count",
          align: "center",
          value: "count",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          sortable: false
        },
      ],
    };
  },
  mounted() {
    // console.log('carga tabla', this.dataTable);
  },
};
</script>

<style>
.tableClustering.table--general td:nth-child(1) {
  color: #00a5ff;
}
</style>
