<template>
	<v-main>
		<v-row>
			<v-col class="d-flex align-center">
				<h1 class="font15pt font-weight-bold mr-2">Semantics Of Words</h1>
				<tooltipComponent :tooltipText="tooltip" />
			</v-col>
		</v-row>
		<v-row>
			<div class="graph-secction col">
				<div class="graph-secction__base pa-3 bgColorMenu">
					<network ref="network" :nodes="nodes" :edges="edges" :options="options" />
				</div>
				<div
					class="
            graph-secction__info
            bgClusteringInfo
            pa-5
            d-flex
            justify-space-between
          "
				>
					<div class="graph-secction__info__title pl-5 d-flex align-center">
						<h1 class="colorPrincipal font15pt font-weight-bold">
							Conversion Cluster base on:
						</h1>
					</div>
					<div class="graph-secction__info__icons pr-5 d-flex align-end">
						<div
							class="
                graph-secction__info__icons__item
                d-flex
                flex-column
                align-center
                mr-5
                position-relative
              "
						>
							<div
								class="
                  graph-secction__info__icons__item__top
                  mb-1
                  d-flex
                  align-center
                "
							>
								<span
									class="rounded-circle d-inline-block d-flex justify-center mr-1"
									style="width: 20px; height: 20px"
								>
									<v-icon size="12" color="#00A5FF">fab fa-facebook</v-icon>
								</span>
								<span
									class="rounded-circle d-inline-block d-flex justify-center mr-1"
									style="width: 20px; height: 20px"
								>
									<v-icon size="12" color="#00A5FF">fab fa-twitter</v-icon>
								</span>
								<span
									class="rounded-circle d-inline-block d-flex justify-center mr-1"
									style="width: 20px; height: 20px"
								>
									<v-icon size="12" color="#00A5FF">fab fa-instagram</v-icon>
								</span>
								<span
									class="rounded-circle d-inline-block d-flex justify-center mr-1"
									style="width: 20px; height: 20px"
								>
									<v-icon size="12" color="#00A5FF">fab fa-linkedin</v-icon>
								</span>
								<template v-for="network in networks">
									<span
										class="rounded-circle d-inline-block d-flex justify-center mr-1 overflow-hidden"
										style="width: 20px; height: 20px; padding: 2px"
										v-if="network.type === 'newspaper'"
										:key="network.name"
									>
										<v-img class="rounded-circle" :src="network.logo" width="12" />
									</span>
								</template>
							</div>
							<div class="graph-secction__info__icons__item__name leyendColor">
								<span>Nodes</span>
							</div>
							<div class="float-content position-relative">
								<div
									class="
                    white
                    elevation-1
                    position-absolute
                    pa-3
                    pt-5
                    pb-5
                    float-info
                  "
								>
									<div class="arrow-top position-absolute">
										<v-icon size="30" color="white"> fa fa-caret-up </v-icon>
									</div>
									<div class="mb-2 font12pt parColor">
										The size of the main node is defined by the number of interactions generated by
										the driver.
									</div>
									<div class="font12pt parColor font-weight-bold mb-1">
										Node
									</div>
									<div class="font11pt colorPrincipal font-weight-bold pl-1">
										Main Node:
									</div>
									<div class="font11pt parColor pl-1">
										Driver (semantic unit).
									</div>
									<div class="font11pt colorPrincipal font-weight-bold pl-1 mt-1">
										Sub Node:
									</div>
									<div class="font11pt parColor pl-1">
										Top 10 words generated by the driver.
									</div>
									<div class="font11pt colorPrincipal font-weight-bold pl-1 mt-1">
										Social Network Icon:
									</div>
									<div class="font11pt parColor pl-1 mb-1">
										indicates the driver's social network.
									</div>
									<div class="font11pt parColor pl-1">
										<span><v-icon size="12" color="#1da1f2">fab fa-twitter</v-icon></span
										><span> Comes from Twitter.</span>
									</div>
									<div class="font11pt parColor pl-1">
										<span><v-icon size="12" color="#1877f2">fab fa-facebook</v-icon></span
										><span> Comes from Facebook.</span>
									</div>
									<div class="font11pt parColor pl-1">
										<span><v-icon size="12" color="#ff69a1">fab fa-instagram</v-icon></span
										><span> Comes from Instagram.</span>
									</div>
									<div class="font11pt parColor pl-1">
										<span><v-icon size="12" color="#0a66c2">fab fa-linkedin</v-icon></span
										><span> Comes from Linkedin.</span>
									</div>
									<template v-for="network in networks">
										<div
											class="font11pt parColor pl-1 d-flex"
											v-if="network.type === 'newspaper'"
											:key="network.name"
										>
											<span class="pr-1">
												<v-img :src="network.logo" width="12" />
											</span>
											<span> Comes from {{ network.name }}.</span>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div
							class="
                graph-secction__info__icons__item
                d-flex
                flex-column
                align-center
                position-relative
              "
						>
							<div
								class="
                  graph-secction__info__icons__item__top
                  mb-3
                  d-flex
                  align-center
                "
							>
								<span class="bgColorpositive d-inline-block" style="width: 10px; height: 3px"></span>
								<span
									class="ml-1 bgColornegative d-inline-block"
									style="width: 10px; height: 3px"
								></span>
								<span
									class="ml-1 bgColorneutral d-inline-block"
									style="width: 10px; height: 3px"
								></span>
							</div>
							<div class="graph-secction__info__icons__item__name leyendColor">
								<span>Lines</span>
							</div>
							<div class="float-content position-relative">
								<div
									class="
                    white
                    elevation-1
                    position-absolute
                    pa-3
                    pt-5
                    pb-5
                    float-info
                  "
								>
									<div class="arrow-top position-absolute">
										<v-icon size="30" color="white"> fa fa-caret-up </v-icon>
									</div>
									<div class="mb-2 font12pt parColor">
										Indicates the sentiment generated by the driver in relation to the associated
										words.
									</div>
									<div class="font11pt colorPrincipal font-weight-bold">
										Line
									</div>
									<div class="font12pt parColor mb-1">
										Each color indicates the type of sentiment of the driver, the relationship and
										the word.
									</div>
									<div class="font11pt parColor pl-1">
										<span
											class="d-inline-block rounded-circle bgColorpositive mr-1"
											style="width: 10px; height: 10px"
										></span
										><span>Positive sentiment.</span>
									</div>
									<div class="font11pt parColor pl-1">
										<span
											class="d-inline-block rounded-circle bgColornegative mr-1"
											style="width: 10px; height: 10px"
										></span
										><span>Negative sentiment.</span>
									</div>
									<div class="font11pt parColor pl-1">
										<span
											class="d-inline-block rounded-circle bgColorneutral mr-1"
											style="width: 10px; height: 10px"
										></span
										><span>Neutral sentiment.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-row>
	</v-main>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
	name: "GraphSemantic",
	components: { tooltipComponent },
	props: {
		words: Array,
	},
	data() {
		return {
			tooltip: "Semantic graphic of words generated around driver",
			edges: [],
			nodes: [],
			options: {
				height: "600px",
				nodes: {
					scaling: {
						label: {
							min: 7,
							max: 13,
						},
						customScalingFunction: function(min, max, total, value) {
							return value / total;
						},
						min: 5,
						max: 50,
					},
					shape: "dot",
					size: 5,
					font: {
						multi: "html",
						size: 16,
						color: "#ffffff",
					},
					borderWidth: 0,
				},
				edges: {
					scaling: {
						min: 10,
						max: 60,
					},
					selectionWidth: 1.8,
				},
			},
			iscColors: {
				pos: "#9fd895",
				neu: "#828a96",
				neg: "#ff7e7e",
			},
		};
	},
	computed: {
		networks() {
			return this.$store.getters["global/networks"];
		},
	},
	methods: {
		emit(eventName, value) {
			return new Promise((resolve, reject) => {
				this.$emit(eventName, value);
				this.$nextTick(resolve);
			});
		},
	},
	watch: {
		words: {
			immediate: true,
			handler: async function() {
				if (this.words.length > 0) {
					const nodos = [];
					const palabras = [];
					const links = [];
					const social_types = [];
					for (const wordItem of this.words) {
						const itemAuthor = wordItem["agg_terms_sample_post_author.keyword"];
						const itemPostSampleWords = wordItem["agg_terms_sample_post_words.keyword"]?.buckets ?? [];
						const itemSampleWords = wordItem.agg_terms_sample_words.buckets.concat(itemPostSampleWords);
						const itemSocialNetwork = wordItem.agg_terms_source_snetwork_id;
						const nodo = {
							id: "",
							label: "",
							color: "#00a5ff",
							image: "",
							size: 40,
							shape: "circularImage",
							value: 0,
						};

						nodo.id = `parent_${wordItem.key}`;
						if (itemAuthor.buckets.length > 0) {
							nodo.label = itemAuthor.buckets[0].key;
						} else {
							nodo.label = wordItem.key;
						}
						if (!wordItem.newspaper_id) {
							switch (itemSocialNetwork.buckets[0].key) {
								case "tw": {
									nodo.image = "https://listen-images.s3.us-east-2.amazonaws.com/twitter_logo.webp";
									social_types.push("tw");
									break;
								}
								case "fb": {
									nodo.image = "https://listen-images.s3.us-east-2.amazonaws.com/facebook_logo.webp";
									social_types.push("fb");
									break;
								}
								case "ig": {
									nodo.image = "https://listen-images.s3.us-east-2.amazonaws.com/instagram_logo.webp";
									social_types.push("ig");
									break;
								}
								case "lk": {
									nodo.image = "https://listen-images.s3.us-east-2.amazonaws.com/linkedin_logo.webp";
									social_types.push("lk");
									break;
								}
								default: {
									nodo.image =
										"https://listen-images.s3.us-east-2.amazonaws.com/newspaper_image.webp";
									social_types.push("nws");
									break;
								}
							}
							nodo.social_network = itemSocialNetwork.buckets[0].key;
						} else {
							// nodo.image = ''
							// social_types.push('')
							this.networks.map((network) => {
								if (network.key === wordItem.newspaper_id) {
									nodo.image = network.logo;
								}
							});
						}
						/* nodo.image =
              itemSocialNetwork.buckets[0].key == "fb"
                ? "https://ompecuador.org/images/redes/facebook-fb-globe-notification-icon-facebook-logo-png-transparent-background.png"
                : "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png"; */
						nodo.value = wordItem.doc_count;
						nodos.push(nodo);
						await this.$emit("changeSocialFilter", [...new Set(social_types)]);
						for (const word of itemSampleWords) {
							const wordNormalized = util.normalizeString(word.key.toLowerCase());
							let sampleSentiment = word["agg_terms_sample_sentiment.keyword"].buckets;

							const maxSentiment = util.maxFromObjectArray(sampleSentiment, "doc_count");
							const childExists = palabras.some((obj) => obj.id == `child_${wordNormalized}`);
							if (!childExists) {
								const palabra = {
									id: `child_${wordNormalized}`,
									label: wordNormalized,
									color: this.iscColors[maxSentiment.key],
								};
								palabras.push(palabra);
								links.push({
									from: `${nodo.id}`,
									to: `${palabra.id}`,
									color: { inherit: "to" },
									unique_id: `${nodo.id}-${palabra.id}`,
								});
							} else {
								links.push({
									from: `${nodo.id}`,
									to: `child_${wordNormalized}`,
									color: { inherit: "to" },
									unique_id: `${nodo.id}-child_${wordNormalized}`,
								});
							}
						}
					}
					this.nodes = [...nodos, ...palabras];
					this.edges = [...util.uniqueObjectInArray(links, "unique_id")];
					console.log("Nodos", JSON.stringify(this.nodes));
					console.log("Edges", JSON.stringify(this.edges));
				} else {
					this.nodes = [];
					this.edges = [];
					console.log("Nodos", JSON.stringify(this.nodes));
					console.log("Edges", JSON.stringify(this.edges));
				}
			},
		},
	},
};
</script>

<style></style>
