<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex align-center">
        <h1 class="font15pt font-weight-bold mr-2">Top Influencers</h1>
        <tooltipComponent :tooltipText="tooltip" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="table--general">
          <v-data-table
            :headers="headers"
            :items="itemsTable"
            :hide-default-header="false"
            :hide-default-footer="true"
            :light="true"
          >
            <template v-slot:[`item.user`]="{ item }">
              <div>
                <span class="mr-5">{{ itemsTable.indexOf(item) + 1 }}</span>
                <v-avatar size="25" class="ml-5">
                  <img alt="user" :src="item.user.avatar" @error="$event.target.src='https://listen-images.s3.us-east-2.amazonaws.com/user-image.png'"/>
                </v-avatar>
                <a  class="ml-2">
                  @{{ item.user.name }}
                </a>
              </div>
            </template>
            <template v-slot:[`item.sentiment`]="{ item }">
              <v-icon v-if="item.sentiment == 'neutral' || item.sentiment == 'neu'" small color="#828a96">
                far fa-hand-point-right
              </v-icon>
              <v-icon v-if="item.sentiment == 'pos'" small color="#9fd895">
                far fa-thumbs-up
              </v-icon>
              <v-icon v-if="item.sentiment == 'neg'" small color="#ff7e7e">
                far fa-thumbs-down
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tooltipComponent from "@/components/base/tooltip.vue";
import { util } from "@isc/styleguide";

export default {
  name: "TopInfluencer",
  components: { tooltipComponent },
  props: {
    influencers: Array,
  },
  data() {
    return {
      tooltip: "Distribution generated around the driver by words.",
      headers: [
        // {
        // text: '',
        // align: 'left',
        // // value: 'user',
        // width: 50,
        // class:['font-weight-bold','titleColor', 'font15pt']
        // },
        {
          text: "User",
          align: "left",
          value: "user",
          class: ["font-weight-bold", "titleColor", "font15pt"],
        },
        {
          text: "Comments",
          align: "center",
          value: "comments",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Followers",
          align: "center",
          value: "followers",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Favourites",
          align: "center",
          value: "favourites",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
        {
          text: "Sentiment",
          align: "center",
          value: "sentiment",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
          sortable: false,
        },
        {
          text: "Score",
          align: "center",
          value: "score",
          class: ["font-weight-bold", "titleColor", "font13pt", "pl-1", "pr-1"],
        },
      ],
      itemsTable: [],
    };
  },
  methods: {
    calcInfluencerScore(favorites, followers) {
      if (followers == 0) {
        return '0.00%';
      }
      const result = ((favorites / followers) * 100).toFixed(2);
      return result + "%";
    }
  },
  watch: {
    influencers: {
      immediate: true,
      handler() {
        const influencersList = [];
        this.influencers.forEach((influencerItem) => {
          const sentiments =
            influencerItem["agg_terms_sample_sentiment.keyword"].buckets;
          const maxSentiment = util.maxFromObjectArray(sentiments, "doc_count");

          const followers = Math.ceil(
            influencerItem["agg_avg_author_followers_count"].value
          );
          const favourites = Math.ceil(
            influencerItem["agg_avg_author_favourites_count"].value
          );
          const avatar =
            influencerItem["agg_terms_author_photo.keyword"].buckets[0].key;

          influencersList.push({
            user: {
              avatar: avatar,
              name: influencerItem.key,
            },
            sentiment: maxSentiment.key,
            score: this.calcInfluencerScore(favourites, followers),
            comments: influencerItem.doc_count,
            followers: followers.toLocaleString(),
            favourites: favourites.toLocaleString(),
          });
        });
        this.itemsTable = influencersList;
      },
    },
  },
};
</script>

<style></style>
